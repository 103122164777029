export const sv = {
    langSelector: "In English",
    login: "Logga in",
    forgotPassword: "Glömt lösenord",
    forgotPasswordTitle: "Återställ lösenord",
    forgotPasswordHelpText:
        "Ange din registrerade e-postadress så skickar vi ett e-postmeddelande med instruktioner om hur du återställer ditt lösenord.",
    vkkMangerTitle: "Välkommen till vår Kundportal. Ett administrationsverktyg för dig som är kund på Vasakronan",
    alreadyHaveAccount: "Jag har redan ett konto. Logga in.",
    needHelp: "Behöver du hjälp?",
    send: "Skicka",
    save: "Spara",
    buy: "Köp",
    proceed: "Vidare",
    continue: "Fortsätt",
    confirm: "Bekräfta",
    ok: "OK",
    next: "Nästa",
    back: "Tillbaka",
    cancel: "Avbryt",
    terminate: "Avsluta",
    "2fa": "2-Faktorautentisering",
    add2FA: "Lägg till en 2-faktorautentiseringsmetod",
    add2FASubtitle: [
        "Vid framtida inloggningar behöver du verifiera att det är du som loggar in i Kundportalen. ",
        "Välj en av följande två metoder för verifiering av ditt användarkonto.",
    ],
    authenticationApp: "Autentiseringsapp",
    authenticationAppInfo:
        "Vid inloggning öppnar du autentiseringsappen för att utläsa din verifieringskod till Kundportalen.",
    fastest: "Snabbast",
    wifi: "Wifi",
    sms: "SMS",
    smsInfo: "Vid inloggning skickas ett SMS med en verifieringskod till ditt registrerade telefonnummer.",
    requiresPhoneAndReception: "Kräver telefon och mottagning",
    install: "Installera",
    installApp: "Installera en autentiseringsapp",
    installAppInfo: [
        "Nedan hittar du förslag på populära autentiseringsappar. Tänk på att installera appen ",
        "på en enhet du alltid har tillgång till för snabb inloggning.",
    ],
    register: "Registrera",
    registerApp: "Registrera i autentiseringsappen",
    registerAppInfo: [
        "Det finns en skanningsfunktion i autentiseringsappen. Notera att du inte ",
        "kan använda mobilens inbyggda QR-scanner eller andra separata skanningappar.",
    ],
    registerAppBottom:
        "När koden ovan är registrerad kommer det börja visas verifikationskoder som ändras med jämna mellanrum.",
    verify: "Verifiera",
    verifyAppRegistration: "Verifiera registreringen",
    verifyAppRegistrationInfo: [
        "Skriv in den verifieringskod som visas i autentiseringsappen för Kundportalen. ",
        "Hinner du inte ange koden, försök igen med nästa verifieringskod som visas.",
    ],
    registerMobile: "Registrera ditt mobilnumnmer",
    registerMobileInfo: "Fyll i det mobilnummer du vill använda när du loggar in i Kundportalen.",
    verifyMobile: "Verifiera ditt mobilnummer",
    verifyMobileInfo: "Vi har skickat ett SMS med en verifieringskod. Ange koden nedan.",
    backup: "Backup",
    backupWithCodes: "Backup med engångskoder",
    backupWithCodesInfo: [
        "Nedan finns engångskoder som kan användas istället för autentiseringsapp eller SMS. ",
        "Spara dessa och förvara på ett säkert ställe. Tar engångskoderna slut kan du generera nya koder ",
        "via din profilsida i Kundportalen. Observera att de gamla engångskoderna slutar fungera när nya genereras.",
    ],
    appLoginVerify: "Fyll i koden som visas i din kopplade autentiseringsapp",
    smsLoginVerify: "Fyll i koden som skickats med SMS till telefonnumret #number",
    verifyWithSms: "Verifiera med SMS istället",
    verifyWithApp: "Verifiera med app istället",
    canUseOneTimeCode: "(Kan använda backup-/engångskod)",
    choosePasswordDescription: "Välj ett lösenord som uppfyller följande kriterier:",
    passwordCriteria1: "Består av minst 8 tecken (bokstäver och siffror)",
    passwordCriteria2: "Undvik alltför enkla lösenord som t.ex. 1234abcd, password123",
    passwordCriteria3:
        "Använd inte lösenord som har anknytning till dig själv, t.ex. namn, användarnamn, personnummer, initialer",
    passwordDeadline: "OBS! Du har en timme på dig från att du tog emot mailet att ändra lösenordet.",
    passwordChangeFail:
        "Tyvärr godkänns inte lösenordet. Notera att det nya lösenordet måste uppfylla kriterierna ovan, samt väljas inom en timme efter det att du mottog mailet om ändra lösenord. Vänligen försök igen.",
    close: "Stäng",
    clear: "Rensa",
    firstName: "Förnamn",
    lastName: "Efternamn",
    email: "E-post",
    sendEmail: "Skicka välkomstmejl Arena från Vasakronan",
    logout: "Logga ut",
    lease: "Hyresavtal",
    allLeases: "Alla hyresavtal",
    tasks: "Ärenden",
    includeCompletedTasks: "Inkl tidigare ärenden",
    invoicePeriod: "Betalningsperiod",
    invoiceArena: "Kontor",
    billingInformation: "Faktureringsuppgifter",
    invoiceSpecification: "Fakturaspecification",
    city: "Stad",
    arena: "Arena",
    mainArena: "Huvudarena",
    arenas: "Arenor",
    showOtherArenas: "Visa andra Arenor",
    arenaOffice: "Arenakontor",
    cancelArena: "Avsluta hyresavtal",
    noUserResults: "Inga användare hittades",
    addArenaContactHelpText: [
        "Välj e-postadress nedan för att bjuda in abonnemangsanvändare till att få tillgång till Arenakontoret. Användaren tilldelas en digital ",
        "nyckel till Arenakontoret och får en mailinbjudan med instruktioner. I Arenakontoret finns ett eget säkert nätverk. ",
        "Användarna får även en inbjudan till Netgraphs serviceportal för administration av enheter i nätverket.",
    ],
    selectEndDateArena: "Ange datum för uppsägning av Arenakontoret.",
    startDate: "Startdatum",
    endDate: "Slutdatum",
    user: "Användare",
    userLower: "användare",
    addUser: "Lägg till användare",
    userSubscriptions: "Användare abonnemang",
    addUserHelpText: [
        "Ange e-postadress till den användare som ska få tillgång till abonnemanget. Användaren tilldelas en digital nyckel till ",
        "Arenan och får en e-postinbjudan med instruktioner och länk till Vasakronans app.",
    ],
    changeUser: "Byt användare",
    changeUserHelpTextDate: "Ange datum då byte av användare ska ske.",
    changeUserHelpTextInstant: "När abonnemanget har ett kommande startdatum så sker användarbytet direkt",
    changeUserHelpTextEmail: "Ange e-postadress till användaren som ska tilldelas abonnemanget.",
    showInformation: "Visa information",
    showExistingContactPerson: "Visa existerande användare",
    yes: "Ja",
    no: "Nej",
    information: "Information",
    currentUser: "Nuvarande användare",
    nextUser: "Nästkommande användare",
    remove: "Ta bort",
    status: "Status",
    noUser: "Ingen aktiv användare",
    noUsers: "Inga aktiva användare",
    subscriptionInformation: "Abonnemangs\u00ADinformation",
    editFinish: "Ändra avslut",
    editFinishDescription: "Är du säker? Abonnemanget kommer tas bort det datum du valt.",
    selectEndDateSubscription: "Ange det datum då abonnemanget ska avslutas.",
    removeFromSpaceflowInfo: "I samband med att abonnemanget avslutas tas användaren automatiskt bort i Spaceflow.",
    earliestSubscriptionEndDate: "Tidigaste slutdatum (baserat på bindingstid, prova-på period och uppsägningstid)",
    warningEarlierThanOfficial: "Du håller på att sätta ett tidigare slutdatum än det ovan beräknade.",
    subscriptionCancelled: "Abonnemanget avslutat",
    includeInactiveSubscriptions: "Inkl. avslutade abonnemang",
    includeTerminatedMemberships: "Inkl. avslutade medlemskap",
    includeArchivedCustomers: "Inkl. arkiverade kunder",
    removeUser: "Ta bort användare",
    removeStaff: "Ta bort personal",
    more: "Mer",
    requestNewSubscriptions: "Ansök om abonnemang",
    company: "Företag",
    addNationalAccess: "Lägg till nationellt tillägg",
    cancelNationalAccess: "Ta bort nationellt tillägg",
    removeSure: "Är du säker?",
    selectStartDateNationalAccess: "Nationellt tillägg",
    selectNationalAccessHelpText: [
        "Med nationellt tillägg gäller abonnemanget på alla Vasakronans Arenor. Användaren kan växla ",
        "mellan Arenorna i Vasakronans app. Ange datum då nationellt tillägg ska aktiveras.",
    ],
    selectEndDateNationalAccess: "Välj slutdatum för nationellt tillägg",
    nationalAccessEndsDescription: [
        "Om Användaren har bokningar som inträffar på andra Arenor efter det nationella tilläggets slutdatum så kommer dessa att finnas kvar och debiteras. ",
        "Säkerställ att Användaren har avbokat alla köp som inte ska finnas kvar på andra Arenor ",
        "efter det nationella tilläggets slutdatum innan du tar bort det nationella tillägget.",
    ],
    cantBuyNationalAccess: "Det går inte att köpa nationellt tillägg på detta abonnemanget!",
    noNationalAccess: "Inget",
    edit: "Ändra",
    add: "Lägg till",
    customerService: "Kundservice",
    contacts: "Kundservice",
    contactUs: "Kontakta oss",
    privacyPolicy: "Integritetspolicy",
    arenaOffices: "Arenakontor",
    arenaCategory: "Arenaavtal",
    subscriptionCategory: "Abonnemang",
    realestatesCategory: "Fastigheter",
    leasesCategory: "Hyresavtal",
    tenantCategory: "Hyresgäst",
    arenaMember: "Arenamedlem",
    dayPassesCategory: "Dagspass",
    dashboardCategory: "Startsida",
    name: "Namn",
    invitations: "Inbjudningar",
    contactPersons: "Avtalsombud",
    contactPersonHelpText: [
        "Med avtalsombud avses en kontaktperson hos hyresgästen som med bindande verkan har rätt att administrera och agera ombud ",
        "för hyresgästen i frågor hänförliga till hyresgästens avtal. Avtalsombudet har rätt att, för hyresgästens räkning, utse en ersättare ",
        "för sig själv som nytt avtalsombud med samma rätt att med bindande verkan företräda hyresgästen och även utse fler avtalsombud.",
        "\n\nAvtalsombudet kommer få rättighet till samtliga avtal som finns kopplade till detta avtal.",
    ],
    contactPersonInfo: "Avtalsombudsinformation",
    editInformation: "Ändra information",
    addContactPerson: "Lägg till avtalsombud",
    addCommunityStaff: "Lägg till Arenapersonal",
    removeContactPerson: "Ta bort användare från avtal",
    removeContactPersonDescription:
        "Vill du ta bort användaren? Det innebär att de inte kommer kunna administrera detta avtal",
    perMonth: "/månad",
    total: "Totalt",
    paymentMethodPDF: "PDF-faktura",
    invoiceAddress: "Fakturaadress",
    invoiceReference: "Fakturareferens",
    addressLine1: "Adressrad 1",
    addressLine2: "Adressrad 2",
    zipCode: "Postnummer",
    paymentMethodPeppol: "E-Faktura (Peppol)",
    peppolID: "Peppol ID",
    errorPeppol: "GLN-id (0088:XXXXXXXXXXXXX) alt. Peppol-id (0007:XXXXXXXXXX)",
    town: "Ort",
    number: "Nummer",
    phone: "Telefon",
    phoneNumber: "Telefonnummer",
    mobileNumber: "Mobilnummer",
    mobileNumberHelperText: "+46XXXXXXXXX",
    invoice: "Faktura",
    invoices: "Fakturor",
    invoiceExpiringDate: "Förfallodatum",
    lastPayDate: "Senast betaldatum",
    invoiceHistory: "Fakturerat",
    currentAnnualAmount: "Aktuellt årsbelopp",
    office: "Arenakontor",
    officeSpaces: "Arbetsplatser",
    membership: "Medlemsavtal",
    memberships: "Medlemsavtal",
    externalMembership: "Extern kund",
    externalMemberships: "Externa kunder",
    subscriptionNumber: "Abonnemangsnummer",
    subscriptionNumberShort: "Abonnemangsnr.",
    subscription: "Abonnemang",
    subscriptions: "Abonnemang",
    product: "Produkt",
    products: "Produkter",
    organizationInvoice: "Företagsfaktura",
    price: "Pris",
    priceExclVat: "Pris exkl. moms",
    priceAmount: "Belopp",
    errorEmail: "Felaktigt format på e-postaddressen",
    errorDate: "Felaktigt format på datumet",
    errorMinDate: "Datumet kan inte vara innan tidigast datum",
    errorMaxDate: "Datumet kan inte vara efter maxdatum",
    errorFutureAdditional: "Datumet får inte vara tomt när en ytterligare användare finns i framtiden",
    errorActivatsDeactivatesDate: "Inaktiveringsdatum kan inte vara tidigare än aktiveringsdatum",
    removeInvitation: "Ta bort inbjudan",
    showInvitation: "Visa inbjudan",
    sendInvitation: "Skicka inbjudan",
    resendInvitation: "Skicka inbjudan igen",
    sendInvitationInactive: "Arenan måste vara aktiv för att inbjudan skall kunna skickas",
    removeArenaMailPreview: [
        "Uppsägning av hyresavtal för avflyttning",
        "\n\n",
        "#org säger härmed upp gällande hyresavtal med nummer #agreementNr avseende det hyrda Arenakontoret, d.v.s. kontorsrummet ",
        "#arenaOfficeName inom #arena i #city för avflyttning till upphörande per #endDate.",
        "\n\n",
        "#cancelCity #cancelDate",
        "\n#org",
        "\ngenom \n #contactPerson",
    ],
    tooShortPassword: "Lösenordet måste vara minst 8 tecken långt",
    notOnlyNumbers: "Lösenordet får inte enbart bestå av siffror",
    passwordsDontMatch: "Lösenorden matchar inte",
    removeSubscription: "Ta bort abonnemang",
    expired: "Utgått",
    activates: "Aktiveras",
    activation: "Aktivering",
    invitedToSpaceflow: "Inbjudan till Spaceflow",
    statusInSpaceflow: "Status i Spaceflow",
    terminatedInSpaceflow: "Avslutas i Spaceflow",
    removingUser: "Användaren kommer tas bort",
    changingUser: "Byter användare",
    weekly: "per vecka",
    monthly: "månad",
    paid: "Betald",
    openContract: "Visa avtal",
    contractNumber: "Avtalsnummer",
    customerNumber: "Kundnummer",
    customerNumberShort: "Kundnr",
    passwordSuccess: "Ditt lösenord har ändrats.",
    amount: "Antal",
    frequency: "Frekvens",
    contractperiod: "Avtalstid",
    supplier: "Leverantör",
    manager: "Chef",
    object: "Objekt",
    building: "Fastighet",
    contract: "Avtal",
    pendingContactPerson: "Vänta på att avtalsombudet ska acceptera inbjudan",
    errorMobile: "Numret får endast vara 6-15 siffror och måste börja med en giltig landskod",
    from: "Fr.o.m.",
    fromAlt: "Från",
    until: "T.o.m",
    contractDuration: "Avtalstid",
    validUntilCapital: "Gäller t.o.m",
    validUntilCapitalShort: "T.o.m",
    validUntil: "gäller t.o.m",
    paymentMethod: "Betalmetod",
    password: "Lösenord",
    currentPassword: "Nuvarande lösenord",
    newPassword: "Ditt nya lösenord",
    newPasswordAgain: "Ditt nya lösenord igen",
    changePassword: "Byt lösenord",
    editProfile: "Redigera kontaktinformation",
    administrate: "Administrera",
    dayPass: "Dagspass",
    dayPasses: "Dagspass",
    date: "Datum",
    ordered: "Beställt",
    orderNumber: "Ordernummer",
    reference: "Referens",
    contactPerson: "Avtalsombud",
    discount: "Rabatt",
    discountOffer: "Rabatterbjudande",
    discountOffers: "Rabatterbjudanden",
    cost: "Kostnad",
    rentPerMonth: "Hyra/månad",
    fee: "Avgift",
    feeType: "Avgiftstyp",
    outstandingCustomerLedger: "Utestående hyresfakturor",
    debitAdress: "Aviadress",
    debitPeriod: "Aviperiod",
    leasePeriod: "Hyresperiod",
    debitPeriodFrom: "Aviperiod fr.o.m.",
    debitPeriodTo: "Aviperiod t.o.m.",
    realestateAndContractTitle: "Fastighetsbeteckning och adress",
    debitAmount: "Aviserat belopp",
    feeSpecification: "Specifikation",
    ofWhichIndex: "Varav index",
    baseAmount: "Basbelopp",
    baseMonth: "Basmånad",
    baseValue: "Basvärde",
    enumerationMonth: "Uppräkningsmånad",
    enumeratedValue: "Uppräkningvärde",
    vat: "Moms",
    vatAmount: "Momsbelopp (#percentage)",
    leaseExpandHelpText: "Vänligen kontakta kontraktansvarig för frågor angående hyresavtalet.",
    amountSubjectToVAT: "Momspliktigt belopp",
    printOrSave: "Skriv ut/ladda ner",
    currentDebits: "Aktuella avgifter",
    earlierCustomerLedgers: "Tidigare hyresfakturor",
    allCustomerLedgers: "Alla hyresavier",
    perSubscriptionAndMonth: "/abonnemang och kalendermånad",
    untilFurtherNoticeLower: "tillsvidare",
    untilFurtherNotice: "Tillsvidare",
    rentsArenaOffice: "så länge medlem hyr Arenakontor",
    emailDaypassUser: "Email dagspassanvändare",
    book: "Boka",
    tac: "Allmänna villkor medlemsavtal",
    dueDate: "Förfallodag",
    unpaid: "Obetald",
    overdueUnpaid: "Över förfallodatum",
    bindingPeriod: "Bindningstid",
    subscriptionPeriod: "Abonnemangstid",
    subscriptionFee: "Ordinarie abonnemangsavgift/månad",
    rentalPeriod: "Hyrestid",
    activatesDate: "Aktiveringsdatum",
    moveInDate: "Tillträdesdag",
    SEK: "kr",
    excludingVat: "exkl. moms",
    includingVat: "inkl. moms",
    totalIncludingVat: "Totalt inkl. moms",
    totalExcludingVat: "Totalt exkl. moms",
    community: "Arena",
    nationalCommunities: "Arenor",
    productAndCommunity: "Produkt och Arena",
    property: "Lokal",
    managerAgent: "Kontraktansvarig",
    userAlreadyExist: "Användaren finns redan.",
    noDataAvailable: "Finns för närvarande ingen data.",
    tasksLoading: "Precis skickat in ett ärende? Så snart ärendet tagits emot kan du se det i tabellen nedan!",
    noOutstandingCustomerLedgers: "Finns för närvarande inga utestående hyresfakturor.",
    buildings: "Byggnader",
    address: "Adress",
    propertyManager: "Fastighetschef",
    squareMeters: "Kvm",
    commonName: "Populärnamn",
    noContactPersons: "Avtalsombud saknas, kontakta Vasakronan",
    contactVasakronan: "Kontakta Vasakronan",
    host: "Värd",
    quantity: "Antal",
    unitPrice: "Á pris",
    purchase: "Köp",
    pendingEmail: "Utskickad inbjudan",
    show: "Visa",
    showLease: "Visa hyresavtal",
    showSubscription: "Visa abonnemang",
    showArenaOffice: "Visa Arenakontor",
    showMembership: "Visa medlemsavtal",
    showExternalMembership: "Visa extern kund",
    showSergelMembership: "Visa Sergelkonferenskund",
    showMembershipAndCustomerDetails: "Visa medlemsavtal och kunduppgifter",
    showPdf: "Visa PDF",
    noPdfUrl: "PDF-faktura skapas",
    removePendingUserDescription: "Nya användaren har inte accepterat inbjudan och kommer tas bort direkt.",
    noRealEstateInfoFound: "Ingen information om fastigheten finns tillgänglig.",
    realestateInformation: "Fastighet\u00ADsinformation",
    noData: "Ingen data",
    activePlural: "Aktiva",
    active: "Aktiv",
    history: "Historik",
    invoiceQuestions: "Fakturafrågor?",
    noCommunities: "Inga communities finns att välja från",
    noHosts: "Inga värdar finns att välja från",
    requestNewArenas: "Ansök om Arenakontor",
    noToken: "Den här länken är inte giltig längre.",
    customerLedgers: "Hyresavier",
    ocr: "Fakturanr/OCR",
    paidAmount: "Betalt belopp",
    usedAmount: "Använt belopp",
    remainingAmount: "Restbelopp",
    overdueAmount: "Förfallet belopp",
    rent: "Hyra",
    rentPerSquareMeter: "Hyra per Kvm",
    reservation: "Reservation",
    comment: "Kommentar",
    comments: "Kommentarer",
    commentOptional: "Kommentar (frivillig)",
    legalCompanyName: "Juridiskt organisationsnamn",
    commercialCompanyName: "Kommersiellt organisationsnamn",
    commercialCompanyNameShort: "Kommersiellt Org.namn",
    orgNumber: "Organisationsnummer",
    orgNumberShort: "Org.nr.",
    branchCode: "Bransch",
    dayPassPaymentInfo: "Kostnaden för dagspass faktureras på företagsfakturan.",
    in: "In:",
    out: "Ut:",
    trialPeriod: "Prova-på-erbjudande",
    trialPeriodShort: "Prova-på",
    free: "kostnadsfritt",
    free2: "Gratis",
    userBeingRemoved: "En ny användare kan tilldelas abonnemanget tidigast",
    noSubscriptionLeftInfo: [
        "Det finns inga abonnemang kopplade till medlemsavtalet. Om ni önskar ansöka om nya ",
        "abonnemang gör du det enkelt via Kundportalen. Medlemsavtalet ligger kvar passivt 1 år från att ",
        "sista abonnemanget sägs upp, därefter avslutas medlemsavtalet automatiskt. För att avsluta ",
        "medlemsavtalet omgående hör av dig till Vasakronan Arena (gå till Kundservice för kontaktinfo).",
    ],
    search: "Sök",
    searchMemberships: "Sök medlemsavtal",
    searchExternalMemberships: "Sök externa kunder",
    searchSergelMemberships: "Sök Sergelkunder",
    searchCommunityStaff: "Sök bland Arenapersonal",
    searchRepresentatives: "Sök ombud",
    searchUser: "Sök användare",
    searchInvites: "Sök bland inbjudningar",
    searchLease: "Sök hyresavtal",
    searchCustomerLedgers: "Sök hyresavier",
    searchSubscriptions: "Sök abonnemang",
    searchRepresentative: "Sök ombud",
    searchProducts: "Sök produkter",
    searchInvoiceOrders: "Sök fakturor",
    searchMembers: "Sök medlemmar",
    download: "Ladda ner",
    downloadEmails: "Ladda ner mailadresser",
    bannerMemberships: [
        "Här finner du information om era medlemsavtal. ",
        "Visa och hantera ditt medlemsavtal och läs aktuella Allmänna villkor.",
    ],
    bannerExternalMemberships: "Här finner du information om externa kunder.",
    bannerSergel: "Här finner du information om kunder på Sergelkonferensen.",
    bannerSubscriptions: [
        "Här specificeras din organisations aktuella och avslutade abonnemang på Arena. ",
        "\n\n​Du kan ansöka om nya abonnemang genom att klicka på knappen ”Mer”.",
    ],
    bannerArenaOffices: [
        "Här kan du ansöka om Arenakontor på Arenan, klicka på ”Mer” för att komma till ansökan.",
        "\n\nDu finner även en översikt över din organisations Arenakontorsavtal. ",
        "Under de tre prickarna finns mer information om respektive kontor och du har även möjlighet att säga upp dem.",
    ],
    bannerDayPasses:
        "Här får du översikt över dina kommande och tidigare dagspass och kan enkelt boka nya dagspass genom att klicka på ”Mer”.​",
    bannerInvoices: [
        "Här får du en sammanställning av era köp på företagsfaktura under innevarande månad samt tillgång till era kommande och historiska fakturor.​",
        "\n\nUnder ”Mer” kan du hantera era faktureringsuppgifter, ändra betalmetod samt se er aktuella köpgräns.​",
    ],
    bannerContactPersons: [
        "Här kan du se avtal som din organisation har med Vasakronan.​",
        "\n\nSom avtalsombud kan du ansöka om att få tillgång till fler avtal inom din organisation.",
        "\n\nFör de avtal som du är avtalsombud för kan du lägga till eller ta bort avtalsombud samt uppdatera kontaktuppgifter genom att klicka på de tre prickarna.",
    ],
    bannerRepresentatives: "Här kan du se de avtal du är ombud för inom din organisation.",
    bannerCommunityStaff: "Här kan du se all personal för de arenor du har tillgång till",
    bannerCostCalculations: "Här kan du se alla genererade kostnadsberäkningar",
    bannerCustomerService: "Här hittar du kontaktuppgifter till Vasakronans kundservice.",
    bannerRealestates:
        "Här hittar du den mest efterfrågade informationen och även dina personliga kontaktpersoner för respektive fastighet.​",
    bannerRealestatesTitle: "Fastighets\u00ADinformation​",
    bannerRepresentativesList: "Här kan du se alla ombud i Kundportalen",
    bannerArenaInformation:
        "Här finns ytterligare information om våra Arenor och fastigheter samt kontaktuppgifter till ansvariga.​",
    bannerArenaInformationTitle: "Arena\u00ADinformation​",
    bannerCostCalculationsTitle: "Kostnads\u00ADberäkningar",
    bannerLeases: "Här ser du alla hyresavtal för vald fastighet samt detaljerad information om era hyresfakturor.​",
    bannerCustomerLedgers: "Se en översiktlig vy över alla hyresavier.",
    bannerTasks: [
        "Här kan du se samtliga inrapporterade ärenden som är kopplade till dina fastigheter. Du kan enkelt skapa ett nytt ärende genom att klicka på ”Skapa ärende”.",
        "Samtliga ärenden inom din organisation finns tillgängliga.",
    ],
    bannerServices: [
        "Här hittar du mer information om våra produkter och erbjudanden.\n",
        "Vi erbjuder ett flertal olika servicetjänster i våra fastigheter och bidrar ",
        "gärna med vår kunskap för att förenkla er vardag på kontoret.",
        "Läs mer här eller kontakta din affärsansvarige för mer ",
        "information om vilka tjänster som finns tillgängliga i just ditt hus.",
    ],
    bannerProducts: "Här ser du alla produkter för de arenor du har tillgång till.",
    bannerInvoiceEvents:
        "Här ser du alla fakturakörningar för de arenor du har tillgång till. Klicka på en körning för att se dess fakturor.",
    bannerMembers: "Här ser du alla personer som är tillagda på dina abonnemang.",
    bannerNews: "Här kan du se nyheter kopplade till era lokaler och berörda fastigheter.",
    profile: "Profil",
    contactInformation: "Kontaktinformation",
    "2faRequired": "Du måste ha minst en 2-faktorautentiseringsmetod registrerad, gärna båda.",
    methodAppInfo: [
        "Registrerade enheter: #count \n",
        "Inloggningskod skickas till autentiseringsappen på din mobiltelefon. Det går att ha upp till 3 olika enheter registrerade. Vi rekommenderar denna autentiseringsmetod.",
    ],
    addMethodApp: "Lägg till app-verifiering",
    editAppDevices: "Redigera enheter",
    totpDevices: [
        "Detta är dina registrerade enheter för verifiering med app. Det kan finnas upp till 3 av dessa på en gång. ",
        "Att ändra namn är bara för egen nytta. Den sista enheten kan bara tas bort om SMS-metoden är aktiverad.",
    ],
    removeAppDevice: "Ta bort enhet",
    addDevice: "Lägg till enhet",
    addDeviceInfo: "Skriv in vad du vill kalla den nya enheten.",
    methodSMSInfo: [
        "Registrerat nummer: #number \n",
        "Inloggningskod skickas via SMS till det telefonnummer du har angett. Det går bara att ha 1 telefonnummer registrerat. ",
        "Om du vill ändra telefonnummer som används för 2-faktorautentisering via SMS måste du först ta bort metoden SMS-verifiering via knappen ",
        "här till höger. Därefter lägger du till SMS-verifiering på nytt via samma knapp och anger ditt nya telefonnummer.",
        "\n \n",
        "Om du har registrerat båda autentiseringsmetoderna (autentiseringsapp och SMS-verifiering) kommer autentiseringsapp vara förhandsvald vid inloggning.",
    ],
    addMethodSMS: "Lägg till SMS-verifiering",
    removeMethodSMS: "Ta bort SMS-verifiering",
    updateMethodSMS: "Byt verifieringsnummer",
    methodBackupCodesInfo: [
        "Kvarvarande engångskoder: #remaining \n",
        "Engångskoder kan användas istället för autentiseringsapp och SMS-verifiering. Koden anges i verifieringsfältet i inloggningsvyn vid inloggning i Kundportalen. ",
        "Engångskoderna bör förvaras fysiskt på ett säkert ställe. Tar engångskoderna slut kan du generera nya koder via din profilsida i Kundportalen. ",
        "Observera att de gamla engångskoderna slutar fungera när nya genereras.",
    ],
    showBackupCodes: "Visa engångskoder",
    backupCodes: "Engångskoder",
    backupCodesInfo:
        "Nedan är dina kvarvarande engångskoder. Du kan antingen klicka på dem för att kopiera dem en och en, eller ladda ner alla på en gång.",
    generateCodesWarning:
        "Du kan när som helst generera nya koder men tänk på att alla dina kvarvarande koder blir oglitiga.",
    generateCodes: "Generera nya koder",
    dashboardTitle: "Hej #firstName #lastName!",
    dashboardBreadcrumbs: [
        "Välkommen till vår kundportal - ett enkelt och smidigt administrationsverktyg för dig som är kund på Vasakronan.",
        "Här kan du bland annat se dina hyres- och medlemsavtal, hålla koll på fakturor och administrera avtalsombud.",
        "Här behandlar vi även personuppgifter enligt GDPR, mer information om det hittar du på ", // Link is added in code
    ],
    teaserArenaMember: "Du kan bli Arenamedlem genom att ansöka om ett medlemsavtal. Läs mer om ansökan här:",
    dialogTitleArenaMember: "Ansök för Arenamedlem",
    teaserTenant: "Är du intresserad över att hyra lokal hos oss? Kolla in vårt utbud av lediga lokaler:",
    dialogTitleTenant: "Ansök för hyresgäst",
    availablePremises: "Lediga lokaler",
    arenaCoworking: "Arena coworking",
    news: "Nyheter",
    showAll: "Visa alla",
    articleFooterCreated: "Skapad: #timestamp",
    articleFooterLastUpdated: "Senast uppdaterad: #timestamp",
    IENotSupported: "Internet Explorer stöds ej. Vi rekommenderar Chrome, Firefox, Safari eller Edge.",
    category: "Kategori",
    description: "Beskrivning",
    reportedBy: "Anmält av",
    reportedByAtDate: "Anmält av #name den #date klockan #time",
    lastModified: "Senast ändrad",
    realEstate: "Fastighet",
    taskNumber: "Ärende nr.",
    myTasks: "Mina",
    allTasksInOrg: "Min organisation",
    buildingTasks: "Fastighetsgemensamma",
    createTask: "Skapa ärende",
    createTaskAction: "Lägg till kommentar",
    currentOrganization: "Vald organisation",
    generalError:
        "Ett oväntat fel inträffade, vänligen försök igen. Om felet kvarstår kontakta Vasakronans Kundservice.",
    generalErrorTitle: "Ett fel uppstod",
    developerDialogTitle: "Felmeddelande för testare & utvecklare",
    developerCopyError: "Kopiera felmeddelande",
    goToStartPage: "Klicka här för att gå till startsidan",
    pageNotFoundTitle: "404 - Sidan hittades inte",
    pageNotFoundDetail:
        "Sidan du försökte nå hittades inte. Det kan bero på stavfel, att sidan flyttats eller att den inte längre är tillgänglig.",
    pageNotImplementTitle: "501 - Sidan är inte implementerad",
    pageNotmplementDetail: "Sidan du försökte nå är ännu inte implementerad. Var vänlig försök igen senare",
    addresses: "Adresser",
    builtAndRebuilt: "Byggt/ombyggt",
    environmentalCertification: "Miljöcertifiering",
    buildingOffice: "Kontor",
    buildingStore: "Butik",
    architect: "Arkitekt",
    buildingFacts: "Fakta om fastigheten",
    howToInProperty: "Så gör vi i fastigheten",
    buildingContactPerson: "Kontaktperson",
    buildingContactPersons: "Kontaktpersoner",
    loadMoreResults: "Läs in fler resultat",
    showPremiseContractAgreement: "Visa Kundportalsavtal",
    arenaInformation: "Arenainformation",
    resetPasswordTitle: "Återställ ditt lösenord för att logga in i Kundportalen",
    resetPasswordExpiredLinkTitle: "Länken för att återställ ditt lösenord är för gammal",
    resetPasswordExpiredLinkText:
        "Den här länken är tyvärr för gammal för att använda. Gå till inloggningssidan och välj “glömt lösenord” så skickas en ny länk till dig. ",
    unsubscribeFromNewsByMailTitle: "Avregistrera nyhetsprenumeration?",
    confirmUnsubscribeFromNews: "Vänligen bekräfta att du vill avsluta din prenumeration av nyhetsbrevet",
    newsletter: "Nyhetsprenumeration",
    newsletterYes: "Ja, jag vill prenumerera på nyhetsbrev",
    goToLoginPage: "Klicka här för att logga in istället",
    leaseInvitationAccepted: "#user har nu tillgång till hyresavtalet med avtalsnummer: #agreementNumber.",
    leaseInvitationConfirm: "Bekräfta förfrågan om behörighet till hyresavtal.",
    requestAccess: "Ansök om behörighet",
    accessToLeaseAgreement: "Ansök om behörighet till hyresavtal",
    sendRequest: "Skicka ansökan",
    requestAccessToLeaseHelpText:
        "Ett mail kommer skickas till avtalsombudet med en förfrågan om behörighet till hyresavtalet.",
    clickToReadMore: "Klicka för att läsa mer",
    showMoreCustomerLedgers: "Visa fler hyresavier",
    noAccessToLease:
        "Du har inte behörighet att hantera det här hyresavatalet. Du kan ansöka om att få behörighet genom att klicka på de tre prickarna till höger i raden.",
    activeLease: "Hyresavtalet är aktivt och har ett eller fler avtalsombud kopplat till avtalet",
    sumOfAboveRows: "Summa av ovanstående rader:",
    pageOfTotal: "Sida #page av #total",
    taskTypeSelect: "Vilken typ av ärende gäller det?",
    describeTask: "Beskriv ärendet",
    describeTaskExplanation:
        "Vänligen undvik att ange känsliga personuppgifter (med detta menas etniskt ursprung, politiska åsikter, religiös eller filosofisk övertygelse, medlemskap i en fackförening, hälsa, en persons sexualliv eller sexuella läggning) i detta textfält.",
    newActionDescription: "Lägg till en kommentar..",
    premise: "Lokal",
    whichAddress: "Vilken adress gäller det?",
    numberOfMaxChars: "#number av #max tecken",
    taskCreated: "Ärende skapat",
    taskActionCreated: "Kommentar inskickad",
    taskCreatedDescription:
        "Tack för att du tar kontakt med oss. Vi jobbar för att ditt ärende ska åtgärdas så snabbt som möjligt. Efter att ärendet tagits emot så kan du se det under 'Mina ärenden'",
    taskActionCreatedDescription:
        "Tack för din kommentar. Vi jobbar för att ditt ärende ska åtgärdas så snabbt som möjligt. Efter att kommentaren tagits emot så kan du se det under 'Mina ärenden'",
    timeReported: "Anmält datum",
    invoiceDate: "Fakturadatum",
    sortDateAsc: "Äldst först",
    sortDateDesc: "Nyast först",
    invoiceNumber: "Fakturanummer",
    invoiceOCR: "OCR",
    bankgiroNumber: "Bankgiro",
    openFile: "Öppna fil",
    readMore: "Läs mer",
    readMoreHere: "Läs mer här",
    generalNews: "Allmän nyhet",
    arenaInfo: "Info om Arena",
    appendices: "Bilagor",
    contactDuringPeriod: "Gällande kontraktstid",
    to: "till",
    invoiceIsSentTo: "Faktura skickas till",
    myContracts: "Mina avtal",
    requests: "Förfrågningar",
    representativesWithinOrganization: "Ombud inom organisationen",
    contractsWithinOrganization: "Övriga avtal inom organisationen",
    organization: "Organisation",
    role: "Roll",
    requestedBy: "Begärd av",
    handleRequest: "Hantera förfrågning",
    handleRequestTitle: "Hantera förfrågan om behörighet till hyresavtal.",
    acceptDescription: " förfrågan för att ge tillgång till information för detta avtal.",
    denyDescription: " förfrågan för att inte ge tillgång till avtalet.",
    handleAction: "Hantera",
    showRepresentatives: "Visa ombud",
    addNewRepresentative: "Lägg till ombud",
    editRepresentative: "Redigera ombud",
    editCommunityStaffUser: "Redigera personal",
    accept: "Acceptera",
    deny: "Neka",
    services: "Information och erbjudanden",
    representative: "Ombud",
    leasesDescription:
        "Som ombud kan du ansöka om att få behörighet till att administrera fler avtal inom din organisation genom att klicka på de tre prickarna",
    noPremiseContract: "Avtalet är inte anslutet till ett Kundportalsavtal",
    addNewRepresentativeHelperText: [
        "Lägg till ytterligare ett ombud inom din organisation och ange behörighetsnivå. ",
        "Ombudet tilldelas då rättigheten att administrera frågor för organisationens räkning.",
    ],
    addNewCommunityStaffHelperText:
        "Under Huvudarena väljer du den Arena personen ska arbeta på. Under Visa andra Arenor väljer du de ytterligare Arenor personen ska ha åtkomst till i Kundportalen. ",
    addNewCommunityStaffHelperText2: "Vid klick på Spara skickas en inbjudan till personens e-post för registrering.",
    leaseInvoiceQuestion: "Fakturafråga",
    invoiceQuestionHelpText: [
        "Uppge din fråga och ange gärna så utförligt som möjligt vad din fråga gäller; ",
        "t.ex. fakturanummer, aktuella avgifter eller liknande.",
    ],
    invoiceQuestionWithNumberHelpText: "Uppge din fråga och ange gärna så utförligt som möjligt vad din fråga gäller.",
    changeCustomerLedger: "Byte av aviadress",
    changeCustomerLedgerHelpText: "Ange om du vill byta aviadress eller ändra faktureringsmetod",
    leaseContractQuestion: "Avtalsfråga",
    leaseContractQuestionHelpText: [
        "Uppge din fråga och ange gärna så utförligt som möjligt vad din fråga gäller; ",
        "tex hyrestid, aktuella avgifter eller liknande",
    ],
    generalQuestionContract: "Kontakta min kontraktsansvariga",
    generalInvoiceQuestion: "Generell fakturafråga",
    indexInvoiceQuestion: "Fråga om index",
    errorReport: "Felanmälan",
    describeQuestion: "Beskriv din fråga så utförligt som möjligt",
    questionTypeSelect: "Välj typ av fråga",
    questionSubTypeSelect: "Ange vilket av följande ditt ärende gäller:",
    attachmentUpload: "Bifoga bilagor",
    recipient: "Mottagare",
    noFileSelected: "Ingen fil vald",
    noFilesSelected: "Inga filer valda",
    selectLease: "Välj först ett hyresavtal",
    showInvoice: "Visa faktura",
    invoiceBasis: "Fakturaunderlag",
    showInvoiceBasis: "Visa fakturaunderlag",
    sendCopyToMail: "Skicka kopia till min e-postadress",
    questionsAboutTheInvoice: "Har du frågor om din faktura?",
    recentlyAddedLease: "Avtalet blev nyligen tillagt i Kundportalen",
    notEnoughSubscriptionsError:
        "Det krävs minst samma antal abonnemang som det finns platser på kontoret kopplat till medlemskapet. Säg först upp ett kontor eller testa ett annat datum",
    notEnoughSubscriptionsTrialError:
        "Det krävs minst samma antal abonnemang som det finns platser på kontoret kopplat till medlemskapet. Säg först upp ett kontor.",
    communityStaff: "Arenapersonal",
    customerPortalAsRepresentative: "Kundportalen Ombud",
    showAsRepresentative: "Visa som Avtalsombud",
    terminateMembershipAgreement: "Avsluta medlemsavtal",
    inactivateMembership: "Arkivera kund",
    activateMembership: "Återställ kund",
    showAsCommunityStaff: "Visa som Arenapersonal",
    communitiesAndRoles: "Arenor och roller",
    created: "Skapad",
    id: "ID",
    offset: "Offset",
    invoiceDateChanged: "Fakturadatum ändrat",
    numberOfSubscriptions: "Antal abonnemang",
    offsetExplaination:
        "Kontrollerar hur många perioder i framtiden som beräkningen ska ta med. Välj 0 för att bara visa den nuvarande månaden.",
    newCostCalculation: "Ny kostnadsberäkning",
    createAndDownload: "Skapa och ladda ner",
    referencePerson: "Kontaktperson",
    createNew: "Skapa ny",
    createNewExternalMembership: "Skapa ny extern kund",
    createNewSergelMembership: "Skapa ny Sergelkund",
    freeTextInternal: "Fritext (intern)",
    paymentInformation: "Betalningsinformation",
    errorEmptyFields: "Vissa fält som krävs är tomma eller felaktigt ifyllda",
    sergel: "Sergel\u00ADkonferensen",
    amountDueIncludingVat: "Att betala inkl. moms",
    toReceiveIncludingVat: "Att få tillbaka inkl. moms",
    createdFrom: "Skapad från",
    createdBy: "Skapad av",
    invoiceEvent: "Faktura\u00ADkörning",
    invoiceEvents: "Faktura\u00ADkörningar",
    invoiceOrders: "Fakturaordrar",
    period: "Period",
    periods: "Perioder",
    ourReference: "Vår referens",
    automaticRun: "Automatisk körning",
    type: "Typ",
    paymentStatusUNPAID: "Obetald",
    paymentStatusRESERVED: "Reserverad",
    paymentStatusPARTIALLY_PAID: "Delvis betald",
    paymentStatusPAID: "Betald",
    paymentStatusUNUSED: "Oanvänd",
    paymentStatusPARTIALLY_USED: "Delvis använd",
    paymentStatusUSED: "Använd",
    orderStatus_created: "Internt fakturaunderlag skapat",
    orderStatus_order_sent: "Order skapad i Payer",
    orderStatus_order_failed: "Faktura kunde inte skapas:",
    orderStatus_order_approved: "Order godkänd i Payer",
    orderStatus_order_internal_failed: "Order skapad i Payer men ett internt problem uppstod",
    orderStatus_order_zero: "Order skickas ej till Payer då totalbeloppet är 0",
    orderStatus_order_partially_credited: "Order delvis krediterad",
    orderStatus_order_entirely_credited: "Order helt krediterad",
    orderStatus_order_not_valid: "Faktura inte kunde inte skapas: Det saknas information som krävs i Kundportalen.",
    orderStatus_invoice_sent: "Faktura/-or skapade i Payer",
    orderStatus_invoice_internal_failed: "Faktura/-or skapade i Payer men ett internal problem uppstod",
    orderStatus_invoice_failed: "Faktura kunde inte skapas i Payer",
    orderStatus_invoice_paid: "Faktura/-or fullt betalda",
    orderStatusShort_created: "Underlag skapat",
    orderStatusShort_order_sent: "Order skapad",
    orderStatusShort_order_failed: "Order misslyckades",
    orderStatusShort_order_approved: "Order godkänd",
    orderStatusShort_order_internal_failed: "Internt orderproblem",
    orderStatusShort_order_zero: "Noll-order",
    orderStatusShort_order_partially_credited: "Del-krediterad",
    orderStatusShort_order_entirely_credited: "Krediterad",
    orderStatusShort_order_not_valid: "Ogiltig order",
    orderStatusShort_invoice_sent: "Faktura skapad",
    orderStatusShort_invoice_internal_failed: "Internt fakturaproblem",
    orderStatusShort_invoice_failed: "Faktura misslyckades",
    orderStatusShort_invoice_paid: "Faktura betald",
    prepayment: "Förskottsbetald",
    normal: "Normal",
    credit: "Kredit",
    creditInvoice: "Kreditfaktura",
    downloadPdf: "Ladda ner PDF",
    paymentTerms: "Betalningsvillkor",
    invoiceBasisId: "FakturaunderlagsID",
    late: "Försenad",
    showProductInfo: "Visa produktinformation",
    productInfo: "Produktinformation",
    createProduct: "Skapa ny product",
    accounts: "Konton",
    revenueAccount: "Intäktskonto",
    vatAccount: "Momskonto",
    costCenter: "Kostnadsställe",
    noticePeriod: "Uppsägningstid",
    productKey: "Produktnyckel",
    errorAlreadyUsed: "Används redan",
    unit: "Enhet",
    hours: "Timmar",
    days: "Dagar",
    month: "Månad",
    months: "Månader",
    errorAtLeastZero: "Måste vara större än 0",
    slots: "Platser",
    nationalAccess: "Nationell produkt",
    baseInformation: "Basinformation",
    serviceSection: "Spaceflow-kategori",
    mustBeUnique: "Enbart en produkt per arena får ha varje Spaceflow-kategori",
    file: "Fil",
    fileName: "Filnamn",
    upload: "Ladda up",
    uploadFile: "Ladda upp fil",
    appPurchases: "Appköp",
    previousMonth: "Föregående månad",
    currentMonth: "Nuvarande månad",
    nextMonth: "Nästa månad",
    fileRow: "Filrad",
    columnName: "Kolumnnamn",
    errorMessage: "Felmeddelande",
    errorMessages: "Felmeddelanden",
    errorsWithoutRow: "Fel som inte tillhör specifika rader",
    notSentOrders: "Ej skickade fakturaunderlag",
    errorOnlyOneFileAllowed: "Endast en fil är tillåten",
    usedCommunityPeriodCombo: "En fil har redan blivit uppladdad för denna arena och period",
    uploadInvoiceEventFileSure:
        "Är du säker på att du vill ladda upp filen trots att valda kombinationen av arena och period redan har fått en fil uppladdad?",
    removeInvoiceEventSure:
        "Är du säker på att du vill ta bort körningen? Du måste då läsa in filen på nytt för att kunna fakturera kund.",
    doInvoiceNow: "Fakturera nu",
    doInvoiceNowSure:
        "I samband med att du godkänner underlaget kommer fakturor skickas ut till kunderna enligt underlaget.",
    exportErrors: "Exportera fel",
    invoiceOrdersAmount: "Antal fakturaunderlag",
    result: "Resultat",
    ordersSentToPayer: "Faktureringen är nu genomförd, och skickade fakturor går att se i Kundportalen.",
    someOrdersFailed: "Notera att en eller flera fakturor inte gick att skicka.",
    country: "Land",
    partOfOrgInfo: "Del av organisationsinformationen",
    documents: "Dokument",
    newDocument: "Nytt dokument",
    documentsUnremovable: "OBS: Det går inte att ta bort uppladdade dokument",
    agreement: "Avtal",
    other: "Övrigt",
    documentNr: "Dokumentnr",
    uploaded: "Uppladdat",
    noDocuments: "Inga dokument uppladdade",
    members: "Användare",
    subscriptionMembers: "Abonnemangs\u00ADanvändare",
    memberInformation: "Användarinformation",
    added: "Tillagd",
    invited: "Inbjuden (#date)",
    invitedNoDate: "Inbjuden",
    deactivated: "Inaktiverad",
    deactivation: "Inaktivering",
    deactivationDate: "Inaktiveringsdatum",
    manageUsers: "Hantera användare",
    addMember: "Lägg till användare",
    editMember: "Redigera användare",
    addMemberInfo:
        "Användaren kommer att få ett inbjudningsmejl från Spaceflow på det valda aktiveringsdatumet, såvida inte hen redan har ett konto på vald Arena.",
    activatesDateInfo: "Användaren kommer att läggas till i Spaceflow på det valda aktiveringsdatumet (på morgonen).",
    deactivationDateInfo: "Användaren kommer att avslutas i Spaceflow på det valda datumet (i slutet av dagen).",
    deactivateMember: "Inaktivera användare",
    deactivateMemberNow: "Inaktivera användare nu",
    deactivateMemberInfo: "Personen kommer inaktiveras från abonnemanget det valda datumet (i slutet av dagen).",
    deactivateMemberSure: "Är du säker på att du vill inaktivera användare med email #email den #date?",
    deactivateMemberNowSure:
        "Är du säker på att du vill inaktivera användare med email #email? Användaren kommer att tas bort direkt. \n\nNotera att det kan ta ett par minuter innan användaren är borttagen från Spaceflow.",
    deactivateNow: "Inaktivera nu",
    deactivateNowDisabled: "Inte möjligt att inaktivera användaren just nu, försök senare",
    removeUserSure: "Är du säker? Användaren tas bort från det här Abonnemanget.",
    removeStaffSure: "Är du säker? Användaren tas bort från Kundportalen.",
    invalidDatesInfo:
        "Användaren är ogiltig på grund av felaktiga datum. För att användaren ska kunna aktiveras i Spaceflow behöver du ta bort denna användare och lägga till en ny med korrekta datum.​",
    subscriptionEndedInfo:
        "Notera att det här abonnemanget har slutdatum #end_date, och vid tiden för abonnemangsavslut kommer samtliga aktiva användare att avslutas i Spaceflow, oavsett inlagt inaktiveringsdatum på användare.",
    inXDays: "Om #days dagar",
    today: "Idag",
    bound: "Personligt",
    boundPlural: "Personliga",
    unbound: "Opersonligt",
    unboundPlural: "Opersonliga",
    overusage: "Överanvändning",
    overused: "Överanvänt",
    statistics: "Statistik",
    subscriptionsCount: "Antal abonnemang",
    expectedRevenue: "Föväntade abonnemangsintäkter",
    invoiced: "Fakturerat",
    credited: "Krediterat",
    discounted: "Rabatterat",
    selectArena: "Välj arena",
    futureGraphData: "Uppskattning baserat på nuvarande information",
    notInvoicedYet: "Inte fakturerat än",
    invoicedGraphTitle: "Fakturerat belopp per månad",
    expectedRevenueGraphTitle: "Abonnemangsintäkter per bokföringsperiod",
    invoicedGraphExplanation:
        "Allt som fakturerats och krediterats (exkl. moms) per arena per fakturaperiod. Abonnemang faktureras i förskott och appköp i efterhand.",
    expectedRevenueGraphExplanation: "Beräknade intäkter och rabatter (exkl. moms) per abonnemang per arena.",
    subscriptionsCountGraphExplanation: "Antal abonnemang per arena.",
    subscriptionUsageGraphTitle: "Utnyttjande av abonnemang",
    subscriptionUsageGraphExplanation: "Hur många personer som nyttjar abonnemangen.",
    details: "Detaljer",
    editBindingPeriod: "Ändra bindingstid",
    editActivationStartDate: "Ändra aktiveringsdatum",
    editOccupancyStartDate: "Ändra tillträdesdatum",
    confirmEditActivationDate:
        "Är du säker? Abonnemanget kommer aktiveras det datum du valt. Notera att om det finns användare på abonnemangen kan dessa påverkas och behöva hanteras. Notera även att eventuell prova-på-period inte automatiskt ändras.",
    confirmEdittOccupancyDate: "Är du säker? Kontorets tillträdesdatum sätts till det du valt.",
    editDiscount: "Ändra rabatt",
    editTrialPeriod: "Ändra prova-på",
    currentBindingPeriod: "Nuvarande bindningstid",
    currentActivationPeriod: "Nuvarande aktiveringsdatum",
    currentOccupancyPeriod: "Nuvarande tillträdesdatum",
    currentTrialPeriod: "Nuvarande slut för prova-på",
    newBindingPeriod: "Ny bindningstid",
    newActivationDate: "Nytt aktiveringsdatum",
    newOccupancyDate: "Nytt tillträdesdatum",
    newTrialPeriod: "Nytt slutdatum för prova-på",
    passed: "Passerad",
    notSpecified: "Ej specificerad",
    startOfDay: "Början av dagen",
    endOfDay: "Slutet av dagen",
    fromOneYearAndForward: "Från 1 år sedan och framåt",
    createDiscount: "Skapa rabatt",
    invoicing: "Fakturering",
    membershipInformation: "Medlemskapsinformation",
    subscriptionUsage: "Abonnemangsnyttjande",
    filterBy: "Filtrera på",
    retry: "Försök igen",
    adjustments: "Justeringar",
    sendAdjustmentsExplanation: [
        "Här finns möjligheten att skicka en extra faktura för ändringar gjorda på det valda medlemskapet.",
        "Den nya fakturan läggs som ett komplement till den senaste fakturan för valt medlemskap.",
        "\n\nDen nya fakturan kan bli en kredit-fakturna ifall kunden ska få tillbaka pengar och en normal ",
        "faktura ifall kunden ska betala mer. Skillnaden mot en 'vanlig' fakturering är att vid denna justering ",
        "skapas en faktura bara för ändringen. Den vanliga fakuran för nästa period kompenserar för ändringen ",
        "tillsammans med den nya periodens avgift.",
    ],
    retries: "Försök",
    oneTimeAmount: "Engångsbelopp",
    monthlyAmount: "Månadsbelopp",
    monthlyAmountExplanation:
        "En löpande rabatt som gäller under en bestämd period, där det faktiska månadsbeloppet räknas ut automatiskt. Angiven rabatt motsvarar en (1) hel månads rabatt. Om kunden endast har rabatt under halva månaden (eller om avtalet avslutas mitt i månaden) får kunden halva rabattbeloppet.",
    oneTimeAmountExplanation:
        "En rabatt på en fast bestämt summa som läggs på en specifik faktura. Kunden får alltid hela rabattbeloppet, så länge det inte är ett högre belopp än vad kunden ska betala. En rabatt kan aldrig generera en kredit.",
    discountDescription: "Beskrivning (visas på fakturan)",
    discountDescriptionText: 'Text visas på fakturan. Exempel "Avtalad rabatt"',
    amountIs: "Belopp utgör ",
    ofMaxAmount: " av maxbelopp ",
    internalNote: "Intern notering",
    internalNoteText: "Text syns endast för Arenapersonal, inte för kund.",
    deleteDiscount: "Ta bort rabatt",
    deleteDiscountDescription: "Är du säker? Rabatten tas bort helt.",
    alternativeRecipient: "Alternativ mottagare",
    alternativeInvoiceRecipient: "Alternativ fakturamottagare",
    selectExisting: "Välj existerande",
    infoAboutExistingRecipient: "Information om existerande mottagare",
    alternativeRecipientWarning: [
        "Observera att alternativ fakturamottagare endast används i undantagsfall, exempelvis när ",
        "Vasakronan ska vara mottagare av faktura för kund som kompenseras för störning i sin egen ",
        "hyreslokal genom access till Arena. Kontakta din Arenachef om du är osäker på om du ska ",
        "använda alternativ fakturamottagare.",
    ],
    confirmAlternativeRecipient: "Bekräfta alternativ mottagare",
    confirmAlternativeRecipientDescription: [
        "Är du säker på att du vill tilldela den valda alternativa fakturamottagaren på det valda medlemskapet?",
        "\n\nObservera att det inte går att redigera information på skapade alternativa mottagare samt att det inte går",
        "att ändra eller ta bort alternativ mottagare för medlemskapet när det har satts.",
    ],
    confirmMembershipTermination: "Bekräfta avslutandet av medlemsavtalet",
    confirmMembershipTerminationDescription: [
        "Är du säker på att du vill avsluta medlemsavtalet?",
        "\n\nNotera att om du väljer att avsluta så kommer avtalet inaktiveras och inte gå att redigera. Om du behöver ett nytt medlemsavtal för denna organisation i framtiden måste du skapa ett nytt.",
    ],
    confirmArchiveMember: "Bekräfta arkiveringen",
    confirmArchiveMemberDescription: [
        "Är du säker på att du vill arkivera denna kund?",
        '\n\nVänligen observera att om du väljer att arkivera kunden, kommer den att tas bort från listan. Du kan visa de arkiverade kunderna genom att klicka på alternativet "Inkludera arkiverade kunder". När du har identifierat dem, har du också möjlighet att återställa kunden vid behov.',
    ],
    confirmUnArchiveMember: "Bekräfta återställningen av kund",
    confirmUnArchiveMemberDescription: [
        "Är du säker på att du vill återaktivera denna kund?",
        "\n\n Vänligen notera att om du väljer att återställa kunden, så kommer den återigen synas i tabellen bland övriga aktiva kunder. Vid behov kan du arkivera kunden igen på samma sätt som förut.",
    ],
    confirmMembershipDate:
        "Sätt ett datum när medlemsavtalet ska avslutas. Ifall ett datum inte väljs så avslutas avtalet idag.",
    inactivated: "Inaktiverat",
    assigned: "Tilldelad",
    events: "Händelser",
    eventNotificationsExplanation: "Jag vill bli informerad via email vid följande händelser",
    eventType_subscription_terminated: "Abonnemang avslutat",
    eventType_office_terminated: "Kontoravtal avslutat",
    eventType_subscription_updated: "Abonnemang uppdaterat",
    eventType_office_updated: "Kontorsavtal uppdaterat",
    eventType_membership_changes: "Medlemskap uppdaterat",
    eventType_order_error: "Fel med fakturaorder",
    eventType_unit4_error: "Fel med Unit4-fil",
    eventType_unit4_uploaded: "Unit4-fil uppladdad",
    typeFiltering: "Typ-filtrering",
    reset: "Återställ",
};
