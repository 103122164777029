import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { LocalizeText, fontType } from "components/localizer";
import { InvoicesContext } from "contexts/invoices";
import { InvoiceOrdersTable } from "components/tableComponents/invoiceOrdersTable";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Skeleton, Typography } from "@mui/material";
import colors from "styles/colors";
import { formatPossibleNullDate, getMonth, sleep } from "utils/helpers";
import { dateFormats } from "utils/formats";
import { parseISO } from "date-fns";
import VKButton from "components/vkButton";
import ConfirmDialog from "components/confirmDialog";
import PrettifyPrice from "components/prettifyPrice";
import { checkTaskStatus } from "adapters/taskAdapter";
import { NotificationContext } from "contexts/notification";
import { LanguageContext } from "contexts/language";
import { usePaginationStore } from "utils/usePaginationStore";
import { BigInfo } from "../../components/invoices/invoiceContent";


const InvoiceEventOrdersView: FC = () => {
    const params = useParams<{ eventUuid: string }>();
    const pagination = usePaginationStore("invoice-orders", undefined, {
        event__uuid: params.eventUuid,
        page_size: 10,
    });
    const {
        fetchInvoiceEvent,
        invoiceEvent,
        approveInvoiceEvent,
        deleteInvoiceEvent,
        isFetchingInvoiceEvent,
    } = useContext(InvoicesContext);
    const { currentLanguage, localize } = useContext(LanguageContext);
    const navigate = useNavigate();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<"remove" | "send" | "result" | undefined>(
        undefined,
    );
    const [isSendingToPayer, setIsSendingToPayer] = useState<boolean>(false);
    const { ...notification } = useContext(NotificationContext);

    useEffect(() => {
        if (params.eventUuid) {
            fetchInvoiceEvent(params.eventUuid);
        }

        // eslint-disable-next-line
    }, []);

    const onApprove = useCallback(async (): Promise<void> => {
        if (!invoiceEvent) {
            return;
        }

        const taskUuid = await approveInvoiceEvent(invoiceEvent.uuid);
        setIsSendingToPayer(true);

        let triesLeft = 60;
        while (triesLeft) {
            await sleep(1000);
            triesLeft--;
            const result = await checkTaskStatus(taskUuid);
            if (result.data.status === "SUCCESS") {
                break;
            } else if (triesLeft === 1) {
                notification.enqueNotification("error_server");
            }
        }

        await pagination.query.refetch();
        const event = await fetchInvoiceEvent(params.eventUuid ?? "");
        if (event.orders_not_invoiced_count !== 0) {
            notification.enqueNotification("error_someOrdersFailed");
        }

        setIsSendingToPayer(false);
        setShowConfirmationDialog("result");
    }, [invoiceEvent, approveInvoiceEvent, pagination, fetchInvoiceEvent, params, notification]);

    if (!invoiceEvent) {
        return null;
    }

    const periodDate = parseISO(invoiceEvent.period_start_date ?? "");

    return (
        <div className="contentWrapper">
            <Typography variant="h1" fontSize="2rem" fontFamily="Vasakronan">
                <LocalizeText tag="invoiceEvent" />
            </Typography>
            <Grid container gap="40px" alignItems="flex-start">
                <Grid container flexDirection="column" width="fit-content">
                    {isFetchingInvoiceEvent ?
                        <Skeleton width="250px" variant="rectangular" height="100px" sx={{ position: "relative", top: "-20px" }} /> :
                        <>
                            <Grid item>
                                <LocalizeText tag="created" />:
                                {formatPossibleNullDate(invoiceEvent.created || null, dateFormats.WEBDATETIME)}
                            </Grid>
                            <Grid item>
                                <LocalizeText tag="period" />: {getMonth(periodDate.getMonth(), currentLanguage)}{" "}
                                {periodDate.getFullYear()}
                            </Grid>
                            <Grid item>
                                <LocalizeText tag="community" />: {invoiceEvent.community?.title || ""}
                            </Grid>
                            <Grid item>
                                <LocalizeText tag="type" />:
                                <LocalizeText
                                    tag={invoiceEvent.created_from_action === "auto" ? "subscriptions" : "appPurchases"}
                                />
                            </Grid>
                            <Grid item>
                                <LocalizeText tag="createdBy" />:{" "}
                                {invoiceEvent.created_by ?? <LocalizeText tag="automaticRun" type={fontType.ITALIC} />}
                            </Grid>
                        </>
                    }
                </Grid>

                <Grid container flexDirection="column" width="fit-content" alignItems="flex-start">
                    {isFetchingInvoiceEvent ?
                        <Skeleton width="250px" height="100px" variant="rectangular" sx={{ position: "relative", top: "-20px" }} /> :
                        <>
                            <Grid item>
                                <LocalizeText tag="totalIncludingVat" />:{" "}
                                <PrettifyPrice amount={invoiceEvent.sum_invoiced_sek.incl_vat} />
                            </Grid>
                            <Grid item>
                                <LocalizeText tag="vat" />: <PrettifyPrice amount={invoiceEvent.sum_invoiced_sek.vat} />
                            </Grid>
                            <Grid item>
                                <LocalizeText tag="amount" /> {localize("memberships").toLocaleLowerCase()}:{" "}
                                {invoiceEvent.orders_count}
                            </Grid>
                        </>
                    }
                </Grid>

                {invoiceEvent.created_from_action === "file" && !invoiceEvent.approved && !isFetchingInvoiceEvent ? (
                    <Grid item gap="10px" ml="auto" minWidth="max-content" sx={{ display: "flex" }}>
                        <VKButton
                            tag="doInvoiceNow"
                            template="primary"
                            onClick={() => setShowConfirmationDialog("send")}
                            isLoading={isSendingToPayer}
                        />
                        <VKButton
                            tag="remove"
                            template="cancel"
                            onClick={() => setShowConfirmationDialog("remove")}
                            disabled={isSendingToPayer}
                        />
                    </Grid>
                ) : null}
            </Grid>
            <Grid container gap={8}>
                {isFetchingInvoiceEvent ? <Skeleton width="540px" height="60px" variant="rectangular" /> : <>
                    {(invoiceEvent.orders_not_invoiced_count > 0 && !isFetchingInvoiceEvent) &&
                        <BigInfo tag="notSentOrders" amount={`${invoiceEvent.orders_not_invoiced_count} st`} color={!invoiceEvent.approved ? colors.vkBlue : colors.errorRed} />
                    }
                    <BigInfo tag="totalExcludingVat" amount={parseInt(invoiceEvent.sum_invoiced_sek.incl_vat) -
                        parseInt(invoiceEvent.sum_invoiced_sek.vat)} currency="SEK" /></>}
            </Grid>


            <InvoiceOrdersTable pagination={pagination} />
            <ConfirmDialog
                open={showConfirmationDialog === "send"}
                title={<LocalizeText tag="doInvoiceNow" />}
                description={<LocalizeText tag="doInvoiceNowSure" />}
                onReject={() => setShowConfirmationDialog(undefined)}
                onAccept={onApprove}
                acceptTag="doInvoiceNow"
                isLoading={isSendingToPayer}
            />
            <ConfirmDialog
                open={showConfirmationDialog === "remove"}
                title={<LocalizeText tag="remove" />}
                description={<LocalizeText tag="removeInvoiceEventSure" />}
                onReject={() => setShowConfirmationDialog(undefined)}
                onAccept={async () => {
                    await deleteInvoiceEvent(invoiceEvent.uuid);
                    navigate("/staff-invoicing/events");
                }}
            />
            <ConfirmDialog
                open={showConfirmationDialog === "result"}
                title={<LocalizeText tag="result" />}
                description={
                    <Grid container direction="column">
                        <Grid item>
                            <LocalizeText tag="ordersSentToPayer" />
                        </Grid>
                        {invoiceEvent.orders_not_invoiced_count > 0 && (
                            <Grid item>
                                <LocalizeText tag="someOrdersFailed" />
                            </Grid>
                        )}
                    </Grid>
                }
                onReject={() => setShowConfirmationDialog(undefined)}
                onAccept={() => setShowConfirmationDialog(undefined)}
                hideIcon={true}
                hideRejectButton={true}
                acceptTag="ok"
            />
        </div>
    );
};

export default InvoiceEventOrdersView;
